<template>
  <v-card flat>
    <v-row class="pl-7 pr-7">
      <v-col cols="6">
        <div class="label-form">Công ty</div>
        <v-card-text
          >{{ form.declare_in_company_name }} -
          {{ form.company_in_tax }}</v-card-text
        >
      </v-col>
      <v-col cols="6">
        <div class="label-form">Số tờ khai nhập</div>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="form.declare_in_no"
              placeholder="Nhập số tờ khai nhập"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-menu
              v-model="showPickDeclareInDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.declare_in_date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  placeholder="Nhập ngày tờ khai nhập"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.declare_in_date"
                @input="showPickDeclareInDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <div class="label-form">Mã hải quan</div>
        <v-text-field
          v-model="form.declare_in_custom_id"
          placeholder="Nhập mã hải quan tờ khai nhập"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <div class="label-form">Nộp thuế</div>
        <v-btn
          @click="fetchThue"
          :loading="loadingCheckThue"
          depressed
          width="100%"
          :disabled="form.is_taxpayer"
        >
          Kiểm tra thuế
        </v-btn>
      </v-col>
      <v-col cols="3">
        <div
          class="d-flex justify-space-around align-center"
          style="height: 100%"
        >
          <div>
            <strong
              >XNK:
              <span>{{ FormatNumber(form.declare_in_xnk) }}đ</span></strong
            >
          </div>
          <div>
            <strong
              >VAT:
              <span>{{ FormatNumber(form.declare_in_vat) }}đ</span></strong
            >
          </div>
        </div>
      </v-col>
      <v-col cols="4">
        <div class="d-flex align-center" style="height: 100%">
          <v-btn
            class="mt-1"
            @click="fetchThongQuan"
            :loading="loadingCheckThongQquan"
            depressed
            width="100%"
            :disabled="
              form.declare_in_flow && form.declare_in_flow_status ? true : false
            "
          >
            Kiểm tra thông quan tờ khai nhập
          </v-btn>
        </div>
      </v-col>
      <v-col cols="8">
        <div class="label-form">Luồng tờ khai nhập</div>
        <v-row>
          <v-col>
            <v-autocomplete
              prepend-inner-icon="mdi-map-marker"
              v-model="form.declare_in_flow"
              :items="declareFlowList"
              outlined
              dense
              item-value="id"
              item-text="name"
            >
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              prepend-inner-icon="mdi-map-marker"
              v-model="form.declare_in_flow_status"
              :items="declareStatusList"
              outlined
              dense
              item-value="id"
              item-text="value"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <div class="label-form">Chứng từ</div>
        <UploadFile
          :typeAllow="['xlsx', 'xls', 'pdf']"
          :reference_id="form.id"
          :table="TABLE"
          :type="TOKHAINHAP"
          :sizeAllow="20"
          v-model="form.file_to_khai_nhap"
          hasImport
          multiple
          @import-item="(item) => handleUploadDeclareIn(item.file)"
          @import-item-pdf="(item) => handleUploadPDF(item.file)"
          :editing="editing"
          noComfirmImport
          :isLoadingHorizontal="isLoadingHorizontal"
        />
      </v-col>
      <v-col cols="12">
        <div class="label-form">Giấy nộp tiền</div>
        <v-row>
          <v-col>
            <v-btn
              :loading="loadingExportFromInfo"
              @click="exportByInfo"
              depressed
              width="100%"
              >Tải</v-btn
            >
          </v-col>

          <v-col>
            <!-- <a href="/files/Tokhai/giay-nop-tien-10388185840_A12.xls" download> -->
            <v-btn
              :loading="loadingExportFromExcel"
              depressed
              width="100%"
              @click="exportFromFile"
            >
              Tải từ file Excel</v-btn
            >
            <!-- </a> -->
          </v-col>

          <v-col>
            <v-btn depressed width="100%"> Gửi Email cho Kế toán</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import XLSX from "xlsx";
import UploadFile from "@/components/upload/UploadFile";
import { TABLE, TOKHAINHAP } from "@/constants/tokhaihaiquan";
import { removeVietnameseTones } from "@/utils/Vietnamconvert";
import API from "@/api/tokhaihaiquan";
import { download } from "@/api/document";
import { saveAs } from "file-saver";
import { numberFormat } from "@/utils/moneyFormat";
import { getErrorMessage } from "@/utils/getErrorMessage";
export default {
  components: { UploadFile },
  props: [
    "value",
    "editing",
    "declareStatusList",
    "declareFlowList",
    "companyList",
    "customsAreaList",
  ],
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data: () => ({
    TABLE,
    TOKHAINHAP,
    files: [],
    showPickDeclareInDate: false,
    showPickDeclareInFlowDate: false,
    loadingCheckThongQquan: false,
    loadingCheckThue: false,
    loadingExportFromExcel: false,
    loadingExportFromInfo: false,
    isLoadingHorizontal: false,
  }),
  methods: {
    async handleUploadPDF(file) {
      this.isLoadingHorizontal = true;
      let formData = new FormData();
      formData.append("file", file);
      const response = await API.importPdfPython(formData);
      let data = response.data.data;
      if (data?.type == "NK") {
        this.handleDataPdf(data);
      } else {
        this.errorMessages("Không đúng mẫu file hải quan tờ khai nhập !");
      }
      this.isLoadingHorizontal = false;
    },
    handleDataPdf(data) {
      if (!data.company_in) {
        return this.errorMessages("Lỗi nhập chứng từ không có mã công ty nhập");
      }
      // handle
      const companyIn = this.companyList.find(
        (item) => item.tax == data.company_in
      );
      let custom = this.customsAreaList.find(
        (item) => item.ma_hq == data.declare_in_custom
      );
      // fill
      this.form.declare_in_flow_status = data.flow_status.includes(
        "(thông quan)"
      );
      this.form.declare_in_no = data.declare_in_no.replaceAll("*", "");
      this.form.declare_in_flow = data.declare_in_flow;
      this.form.declare_in_company_id = companyIn?.id;
      this.form.declare_in_company_name = companyIn?.name;
      this.form.declare_in_custom_id = custom?.ma_hq;
      this.form.declare_in_custom_name = custom?.name;
      this.form.declare_in_custom_area_id = custom?.id;
      this.form.company_in = companyIn?.id;
      this.form.company_in_tax = data.company_in;
      this.form.declare_in_date = this.formatDate(data.declare_in_date);
      this.form.price = data.prices.replaceAll(".", "");
      this.form.quantity = data.quantity
        .replaceAll(".", "")
        .replaceAll(",", "");
    },
    async fetchThue() {
      this.loadingCheckThue = true;
      const res = await API.checkThue({
        number: this.form.declare_in_no,
        tax: this.form.company_in_tax,
      });
      if (res && res.isDone) {
        let { XNK, VAT } = res.data;
        if (XNK) {
          this.form.declare_in_xnk = XNK;
        }
        if (VAT) {
          this.form.declare_in_vat = VAT;
        }
      }
      this.loadingCheckThue = false;
    },
    async fetchThongQuan() {
      this.loadingCheckThongQquan = true;

      const res = await API.checkThongQuan({
        company_tax: this.form.company_in_tax,
        declare_no: this.form.declare_in_no,
        declare_custom: this.form.declare_in_custom_id,
        declare_date: this.form.declare_in_date,
      });
      if (res && res.isDone) {
        let { status, flow, declare_no } = res.data.data;
        if (status) {
          this.form.declare_in_flow_status = status;
        }
        if (flow) {
          this.form.declare_in_flow = flow;
        }
        if (declare_no) {
          this.form.declare_in_no = declare_no;
        }
      }
      this.loadingCheckThongQquan = false;
    },
    infoMessange(message, timeout = 2500) {
      this.$toast.info(message, {
        position: "top-center",
        timeout,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    errorMessages(message, timeout = 2000) {
      this.$toast.error(message, {
        position: "top-center",
        timeout,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    formatDate(date) {
      const splitDate = date.split(" ")[0];
      const day = splitDate.split("/")[0];
      const month = splitDate.split("/")[1];
      const year = splitDate.split("/")[2];
      if (year && month && day) {
        return year + "-" + month + "-" + day;
      }
    },
    async handleUploadDeclareIn(file) {
      const fileReader = new FileReader(); // construction function that can read the file content
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary", // binary
          });

          const wsname = workbook.SheetNames[0]; //take the first sheet

          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); //Get the data in this table

          let flowStatus = false;

          if (
            ws
              .find((item) => item.__rowNum__ === 1)
              .__EMPTY_4.includes("(thông quan)")
          ) {
            flowStatus = true;
          }

          this.form.declare_in_no = ws
            .find((item) => item.__rowNum__ === 2)
            .__EMPTY_25.replaceAll("*", "");

          this.form.declare_in_flow = ws.find(
            (item) => item.__rowNum__ === 5
          ).__EMPTY_7;

          this.form.declare_in_flow_status = flowStatus;
          let companyIn = {
            name: ws.find((item) => item.__rowNum__ === 9),
          };
          const companyInId = this.companyList.find(
            (item) =>
              item.tax === ws.find((item) => item.__rowNum__ === 9).__EMPTY_6
          )?.id;

          const companyInName = this.companyList.find(
            (item) =>
              item.tax === ws.find((item) => item.__rowNum__ === 9).__EMPTY_6
          )?.name;
          let custom = this.customsAreaList.find(
            (item) =>
              item.ma_hq ===
              ws
                .find((item) => item.__rowNum__ === 29)
                .__EMPTY_19.substring(0, 4)
          );
          const customId = custom?.id;
          const customCode = custom?.ma_hq;

          const customname = this.customsAreaList.find(
            (item) =>
              item.ma_hq ===
              ws
                .find((item) => item.__rowNum__ === 29)
                .__EMPTY_19.substring(0, 4)
          )?.name;

          this.form.price = ws
            .find((item) => item.__rowNum__ === 44)
            .__EMPTY_14.replaceAll(".", "");
          this.form.quantity = ws
            .find((item) => item.__rowNum__ === 36)
            .__EMPTY_9.replaceAll(".", "");

          this.form.declare_in_custom_id = customCode;
          this.form.declare_in_custom_name = customname;
          this.form.declare_in_custom_area_id = customId;

          this.form.company_in = companyInId;
          this.form.company_in_tax = ws.find(
            (item) => item.__rowNum__ === 9
          ).__EMPTY_6;
          this.form.declare_in_company_id = companyInId;
          this.form.declare_in_company_name = companyInName;
          this.form.declare_in_date = this.formatDate(
            ws.find((item) => item.__rowNum__ === 7).__EMPTY_5
          );
          this.infoMessange("Thành công");
        } catch (e) {
          this.errorMessages("Lỗi nhập chứng từ");
        }
      };
      fileReader.readAsBinaryString(file); // read file, trigger onload
    },
    async exportFromFile() {
      if (this.form.file_to_khai_nhap.length > 0) {
        let file = await this.fileHandleReturn(this.form.file_to_khai_nhap);

        const fileReader = new FileReader(); // construction function that can read the file content
        fileReader.onload = async (ev) => {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary", // binary
          });

          const wsname = workbook.SheetNames[0]; //take the first sheet

          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); //Get the data in this table
          let companyIn = {
            tax: ws.find((x) => x.__rowNum__ == 9).__EMPTY_6,
            name: ws.find((x) => x.__rowNum__ == 10).__EMPTY_6,
          };
          let ws_find_company_in = this.companyList.find((item) => {
            return (
              item.tax == companyIn.tax ||
              removeVietnameseTones(item.name)
                .toLowerCase()
                .includes(this.handleNameCompanyIn(companyIn.name))
            );
          });
          let DATE = ws.find((item) => item.__rowNum__ === 7).__EMPTY_5;
          DATE = DATE ? this.formatDate(DATE) : DATE;
          let No = ws.find((x) => x.__rowNum__ == 3).__EMPTY_3;
          let NK = ws.find((x) => x.__rowNum__ == 67).__EMPTY_6;
          let GTGT = ws.find((x) => x.__rowNum__ == 68).__EMPTY_6;
          let Tong = ws.find((x) => x.__rowNum__ == 67).__EMPTY_22;
          let ma_loaihinh = ws.find((x) => x.__rowNum__ == 5).__EMPTY_14;
          let ma_hq = ws
            .find((item) => item.__rowNum__ === 29)
            .__EMPTY_19.substring(0, 4);
          ma_loaihinh = ma_loaihinh ? ma_loaihinh.split(" ")[0] : ma_loaihinh;
          if (!ws_find_company_in.name || !No || !DATE) {
            this.warningExport();
            return;
          }
          let params = {
            company_name: ws_find_company_in.name,
            No,
            NK,
            ma_hq,
            GTGT,
            Tong,
            DATE,
            ma_loaihinh,
          };
          try {
            this.loadingExportFromExcel = true;
            await this.exportApi(params);
          } finally {
            this.loadingExportFromExcel = false;
          }
        };
        fileReader.readAsBinaryString(file); // read file, trigger onload
      }
    },
    async fileHandleReturn(data) {
      let d = data[data.length - 1];
      if (this.editing) {
        if (d.id) {
          const res = await download(d.id);
          return new File([res], d.name);
        } else if (d.file) {
          return d.file;
        }
      } else {
        return d.file;
      }
    },
    warningExport() {
      this.$toast.warning("Vui lòng nhập đủ thông tin!", {
        position: "top-center",
        timeout: 1500,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    handleNameCompanyIn(name) {
      if (!name) {
        return "";
      }
      name == removeVietnameseTones(name);
      name = name.toLowerCase();
      if (name.includes("ngoi sao xanh") || name.includes("greenstar")) {
        return "ngoi sao xanh";
      } else if (name.includes("thuan thanh")) {
        return "thuan thanh";
      }
      return name;
    },
    warningThongQuan() {
      this.$toast.warning("Vui lòng cập nhập đủ thông tin!", {
        position: "top-center",
        timeout: 1500,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    async exportByInfo() {
      if (
        !this.form.declare_in_company_name ||
        !this.form.declare_in_no ||
        !this.form.declare_in_date
      ) {
        this.warningExport();
        return;
      }
      let NK = this.form.declare_in_xnk ? this.form.declare_in_xnk : 0;
      let GTGT = this.form.declare_in_vat ? this.form.declare_in_vat : 0;
      let params = {
        company_name: this.form.declare_in_company_name,
        No: this.form.declare_in_no,
        DATE: this.form.declare_in_date,
        ma_loaihinh: "A12",
        ma_hq: this.form.declare_in_custom_id,
        NK: numberFormat(NK),
        GTGT: numberFormat(GTGT),
        Tong: numberFormat(NK + GTGT),
      };
      try {
        this.loadingExportFromInfo = true;
        await this.exportApi(params);
      } finally {
        this.loadingExportFromInfo = false;
      }
    },
    async exportApi(params) {
      const res = await API.exportPaymentSlip(params);
      saveAs(new Blob([res.data]), `GiayNopTien_${params.No}.xls`);
    },
    FormatNumber(number) {
      return numberFormat(number);
    },
  },
};
</script>
