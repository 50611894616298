export const FlowList = [
  {
    id: 0,
    name: "Chưa",
    styles: {
      color: "#666",
      background: "#b7b7b7",
    },
  },
  {
    id: 1,
    name: "Xanh",
    styles: {
      color: "#004DF4",
      background: "#05AD05",
    },
  },
  {
    id: 2,
    name: "Vàng",
    styles: {
      color: "#ffe500",
      background: "#fffdf3",
    },
  },
  {
    id: 3,
    name: "đỏ",
    styles: {
      color: "#f40000",
    },
  },
];

export const CHUNG_TU = "CT";
export const TOKHAIXUAT = "TKX";
export const TOKHAINHAP = "TKN";
export const TABLE = "CUSTOMS_DECLARES";

export const BgColorAlert = {
  xam: "#b7b7b7",
  do: "#fb2003",
  vang: "#e6c071",
  vang_dam: "#ffae00",
  xanh:"#05AD05"
};

export const StatusGraft = [
  {
    id: 1,
    text: 'Thành công'
  },
  {
    id: 2,
    text: 'Không tìm thấy tờ khai'
  },
  {
    id: 3,
    text: 'Tờ khai trùng'
  },
]