import { AApi } from "@/service/AApi";

const API = new AApi(
  { url: "customsdeclares", isConvertFormData: true },
  {
    importCustomsDeclares: {
      async request(data) {
        return this.sdk({
          url: "importcustomsdeclares",
          method: "post",
          data,
        });
      },
    },

    deleteMultiple: {
      async request(data) {
        return this.sdk({
          url: "/customsdeclares-delete-multiple",
          method: "delete",
          data,
        });
      },
    },

    exportPaymentSlip: {
      async request(params) {
        return this.sdk({
          url: "/exportPaymentSlip",
          responseType: "blob",
          params,
        });
      },
    },

    exportMultiplePaymentSlip: {
      async request(params) {
        return this.sdk({
          url: "/exportPaymentSlip-multiple",
          method: "get",
          responseType: "blob",
          params,
        });
      },
    },

    checkThongQuan: {
      async request(params) {
        return this.sdk({
          url: "/customs-declares/checkThongQuan",
          method: "get",
          params,
        });
      },
    },
    checkThue: {
      async request(params) {
        return this.sdk({
          url: "/customs-declares/checkThue",
          method: "get",
          params,
        });
      },
    },

    importPDF: {
      async request(data) {
        return this.sdk({
          url: "/customs-declares/import-pdf",
          method: "post",
          data,
        });
      },
    },

    importGraft: {
      async request(data) {
        return this.sdk({
          url: "/customs-declares/import-graft",
          method: "post",
          data,
        });
      },
    },
    // khaimp import pdf
    importPdfPython: {
      async request(data) {
        return this.sdk({
          url: "/customs-declares/import-pdf",
          method: "post",
          data,
        });
      },
    },
  }
);
export default API;
