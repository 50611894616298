<template>
  <PageListLayout
    :title="`${editing ? 'Cập nhật' : 'Thêm'} tờ khai hải quan`"
    noFilter
    useBack
    linkBack="/tokhai/tokhaihaiquan"
  >
    <template slot="side-right">
      <div class="mr-4">
        <v-btn
          depressed
          color="success"
          @click="handleSubmit"
          :loading="btnLoading"
        >
          <v-icon left>
            {{ editing ? "mdi-account-edit" : "mdi-account-plus" }}</v-icon
          >
          {{ editing ? "Cập nhật" : "Thêm mới" }}
        </v-btn>
      </div>
    </template>
    <v-form class="mt-2" ref="form">
      <v-row justify="center">
        <v-col class="pt-0" lg="12" sm="12" md="12" xs="12">
          <v-layout column>
            <div class="d-flex">
              <div class="flex-grow-0">
                <v-tabs
                  vertical
                  color="var(--primary-color)"
                  v-model="tab"
                  align-with-title
                >
                  <v-tab class="ml-0">Thông tin chung</v-tab>
                  <v-tab class="ml-0">Tờ khai xuất</v-tab>
                  <v-tab class="ml-0">Tờ khai nhập</v-tab>
                </v-tabs>
              </div>
              <div class="flex-grow-1">
                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <ThongTinChung
                      v-model="form"
                      :customersList="customersList"
                      :companyList="companyList"
                      :editing="editing"
                      :billsList="billsList"
                      @companyInChange="companyInChange"
                      @companyOutChange="companyOutChange"
                    />
                  </v-tab-item>
                  <v-tab-item>
                    <ToKhaiXuat
                      v-model="form"
                      :customersList="customersList"
                      :customsAreaList="customsAreaList"
                      :companyList="companyList"
                      :declareFlowList="FlowList"
                      :declareStatusList="declareStatusList"
                      :editing="editing"
                    />
                  </v-tab-item>
                  <v-tab-item>
                    <ToKhaiNhap
                      :editing="editing"
                      v-model="form"
                      :declareStatusList="declareStatusList"
                      :companyList="companyList"
                      :declareFlowList="FlowList"
                      :customsAreaList="customsAreaListTKN"
                    />
                  </v-tab-item>
                </v-tabs-items>
                <v-row class="pl-6 pr-6 mt-2 pb-4">
                  <v-col cols="12" class="zero-vertical">
                    <div class="label-form">Nhập ghi chú</div>
                    <v-textarea
                      v-model="form.description"
                      placeholder="Ghi chú"
                      outlined
                      dense
                      no-resize
                      hide-details
                    ></v-textarea>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-layout>
        </v-col>
      </v-row>
    </v-form>
  </PageListLayout>
</template>
<script>
import API from "@/api/tokhaihaiquan";
import { getCompanyManagers } from "@/api/congty";
import HOADON from "@/api/hoadon";
import { getAllCustomsArea } from "@/api/khuvuchaiquan";
import { TYPE as TYPE_NHAP } from "@/constants/congtynhapkhau";
import { TYPE as TYPE_XUAT } from "@/constants/congtyxuatkhau";
import ThongTinChung from "./Detail/ThongTinChung";
import ToKhaiXuat from "./Detail/ToKhaiXuat";
import ToKhaiNhap from "./Detail/ToKhaiNhap";
import { FlowList } from "@/constants/tokhaihaiquan";
import { index as getChiNhanhNganHang } from "@/api/chinhanhnganhang";
export default {
  components: { ThongTinChung, ToKhaiXuat, ToKhaiNhap },
  data: () => ({
    FlowList,
    showPickDeclareInDate: false,
    showPickDeclareInFlowDate: false,
    showPickDeclareOutFlowDate: false,
    showPickDeclareOutDate: false,
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {
      code: "",
      name: "",
      unit: "",
      expected_price: 0,
      url_image: "",
      description: "",
    },
    defaultItem: {
      code: "",
      name: "",
      unit: "",
      expected_price: 0,
      url_image: "",
      description: "",
    },
    showPickRequestDate: false,
    showPickResponseDate: false,
    date: null,
    editing: false,
    customersList: [],
    companyList: [],
    billsList: [],
    customsAreaList: [],
    customsAreaListTKN: [], // danh sách mã hải quan tờ khai nhập
    requestTypeList: [
      { value: 1, name: "Yêu cầu báo giá mới" },
      { value: 2, name: "Yêu cầu báo giá thay thế" },
    ],
    tab: 0,
    btnLoading: false,
    form: {
      company_out: null,
      company_out_tax: null,
      company_in: null,
      company_in_tax: null,
      price: null,
      quantity: null,
      bill_id: null,
      contract_name: null,
      contract_path: null,
      description: null,
      declare_out_company_id: null,
      declare_out_company_name: null,
      declare_out_no: null,
      declare_out_date: null,
      declare_out_custom_id: null,
      declare_out_custom_name: null,
      declare_out_custom_area_id: null,
      declare_out_flow: 0,
      declare_out_flow_status: false,
      declare_out_flow_date: null,
      declare_out_contract_file_name: null,
      declare_out_contract_file_path: null,
      declare_out_description: null,
      declare_in_company_id: null,
      declare_in_company_name: null,
      declare_in_no: null,
      declare_in_date: null,
      declare_in_custom_id: null,
      declare_in_custom_name: null,
      declare_in_custom_area_id: null,
      declare_in_flow: 0,
      declare_in_flow_status: false,
      declare_in_flow_date: null,
      declare_in_contract_file_name: null,
      declare_in_contract_file_path: null,
      declare_in_payment_file_name: null,
      declare_in_payment_file_path: null,
      declare_in_description: null,
      file_chung_tu: [],
      file_to_khai_xuat: [],
      file_to_khai_nhap: [],
      declare_in_xnk: 0,
      declare_in_vat: 0,
    },
    headers: [
      // { text: "STT", width: "100", sortable: false },
      // { text: "Icon", value: "icon", sortable: false },
      {
        text: "Mã sản phẩm",
        align: "start",
        sortable: false,
        value: "code",
      },
      { text: "Tên sản phẩm", value: "name" },
      { text: "ĐVT", value: "unit" },
      { text: "Giá bán dự kiến", value: "expected_price" },
      { text: "Hình ảnh", value: "url_image" },
      { text: "Ghi chú", value: "description" },
      { text: "Hành động", value: "actions", sortable: false },
    ],
    declareStatusList: [
      { id: false, value: "Chưa thông quan" },
      { id: true, value: "Thông quan" },
    ],
    billsCollected: [],
  }),

  created() {
    if (this.$route.params.id) {
      this.editing = true;
      this.getCustomsDeclareById(this.$route.params.id);
    }
  },

  mounted() {
    this.tab = 1;
    this.getCustomersList();
    this.getCompanyList();
    this.getCustomsAreaList();
    this.getCustomersAreaListTKN();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Thêm tờ khai hải quan"
        : "Sửa tờ khai hải quan";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "form.declare_out_flow": function (val) {
      this.form.declare_out_flow = val ? parseInt(val) : 0;
    },
    "form.declare_in_flow": function (val) {
      this.form.declare_in_flow = val ? parseInt(val) : 0;
    },
  },
  methods: {
    async getCustomsDeclareById(id) {
      const res = await API.getDetail(id);
      this.form = res;
      this.callbackBill();
    },
    getImageUrl(url) {
      return process.env.VUE_APP_BASE + url;
    },

    async importData(data) {
      await API.importCustomsDeclares(data);
    },
    async handleSubmit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        let targetId = null;
        if (this.editing) {
          targetId = await this.submitUpdate();
        } else {
          targetId = await this.submit();
        }
        this.$router.push({
          path: `/tokhai/tokhaihaiquan?targetId=${targetId}`,
        });
      } else {
        this.tab = 0;
      }
    },

    async submit() {
      this.btnLoading = true;
      let data = {...this.form}
      const result = await API.create(data);
      this.$emit("on-done");
      this.btnLoading = false;
      return result.data;
    },
    async getCustomersList() {
      this.customersList = await getCompanyManagers({
        type: TYPE_XUAT,
        isPaging: false,
      });
    },
    async getCompanyList() {
      this.companyList = await getCompanyManagers({
        type: TYPE_NHAP,
        isPaging: false,
      });
    },
    async getCustomsAreaList() {
      this.customsAreaList = await getAllCustomsArea();
    },
    async getCustomersAreaListTKN() {
      const res = await getChiNhanhNganHang({ paging: false });
      this.customsAreaListTKN = res.data;
    },
    clearData() {
      this.form = this.$options.data.call(this).form;
      this.ProductData = [];
      this.selectedData = [];
    },
    async submitUpdate() {
      this.btnLoading = true;
      let data = { ...this.form };
      data.bill = [...data.bill].map((x) => {
        if (typeof x === "object" && x !== null) {
          return x.id;
        } else {
          return x;
        }
      });
      await API.update(data.id, data);
      this.btnLoading = false;
      return data.id;
    },
    onChangeCustomers(event) {
      this.form.customer_name = this.customersList.find(
        (item) => item.id === event
      ).name;
      this.form.customer_address = this.customersList.find(
        (item) => item.id === event
      ).address;
    },
    onChangeProducts() {
      this.ProductData = [];
      for (let i in this.selectedData) {
        if (
          this.ProductData.find((item) => item.id === this.selectedData[i]) ===
          undefined
        ) {
          this.ProductData.push(
            this.productsList.find((item) => item.id === this.selectedData[i])
          );
        }
      }
      this.form.ProductPriceRequestsDetails = this.ProductData;
    },

    editItem(item) {
      this.editedIndex = this.ProductData.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.ProductData.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.ProductData.splice(this.editedIndex, 1);
      this.form.ProductPriceRequestsDetails = this.ProductData;
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.$refs.form_detail.validate();
      if (this.$refs.form_detail.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.ProductData[this.editedIndex], this.editedItem);
        } else {
          this.ProductData.push(this.editedItem);
        }
        this.form.ProductPriceRequestsDetails = this.ProductData;
        this.close();
      }
    },

    formatDate(date) {
      const splitDate = date.split(" ")[0];
      const day = splitDate.split("/")[0];
      const month = splitDate.split("/")[1];
      const year = splitDate.split("/")[2];
      return year + "-" + month + "-" + day;
    },

    companyOutChange(value, listsource) {
      let find = listsource.find((item) => item.id === value);
      if (find) {
        this.form.company_out_tax = find.tax;
        this.form.declare_out_company_id = value;
        this.form.declare_out_company_name = find.name;
        this.callbackBill();
      }
    },

    companyInChange(value, listsource) {
      let find = listsource.find((item) => item.id === value);
      if (find) {
        this.form.company_in_tax = find.tax;
        this.form.declare_in_company_id = value;
        this.form.declare_in_company_name = find.name;
        this.callbackBill();
      }
    },
    onChange(e) {
      this.billsCollected = [];
      for (let i in e) {
        this.billsCollected.push(
          this.billsList.find((item) => item.id === e[i])
        );
      }
    },
    async callbackBill() {
      const res = await HOADON.list({...this.form, paginate: false});
      this.billsList = res.data;
    },
  },
};
</script>
<style scoped>
.zero-vertical {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
/deep/.v-list-item.header__right__list_item {
  height: 40px;
}
/deep/ .header__right__list_item .v-list-item__content {
  padding-bottom: 0;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #000000f9;
}
.v-tab {
  justify-content: end;
}
</style>
