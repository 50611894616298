<template>
  <v-card flat>
    <v-row class="pl-7 pr-7">
      <v-col cols="6">
        <div class="label-form">Mã số thuế(Công ty xuất)</div>
        <v-autocomplete
          prepend-inner-icon="mdi-map-marker"
          v-model="form.company_out"
          :items="customersList"
          outlined
          dense
          item-value="id"
          :item-text="(item) => item.name + ' - ' + item.tax"
          :rules="company_outRules"
          hide-details="auto"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="6">
        <div class="label-form">Mã số thuế(Công ty nhập)</div>
        <v-autocomplete
          prepend-inner-icon="mdi-map-marker"
          v-model="form.company_in"
          :items="companyList"
          outlined
          dense
          item-value="id"
          :item-text="(item) => item.code + ' - ' + item.tax"
          :rules="company_inRules"
          hide-details="auto"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="6">
        <div class="label-form">Số tiền</div>
        <v-text-field-money
          v-model="form.price"
          placeholder="Nhập số tiền"
          :rules="priceRules"
          outlined
          dense
          hide-details="auto"
          v-bind:properties="{
            prefix: 'VNĐ',
            readonly: false,
            disabled: false,
            outlined: true,
            clearable: false,
            dense: true,
            placeholder: 'Nhập số tiền',
          }"
          v-bind:options="{
            locale: 'vi-VN',
            length: 11,
            precision: 0,
            empty: null,
          }"
        />
      </v-col>
      <v-col cols="6">
        <div class="label-form">Khối lương</div>
        <v-text-field
          v-model="form.quantity"
          :rules="quantityRules"
          placeholder="Nhập khối lượng (kg)"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <div class="label-form">Hóa đơn</div>
        <v-autocomplete
          placeholder="Chọn số hóa đơn"
          prepend-inner-icon="mdi-image-area"
          v-model="form.bill"
          :items="billsList"
          outlined
          dense
          :item-text="
            (item) =>
              item.company_out_name +
              ' | Số HĐ: ' +
              item.bill_no +
              ' | ' +
              item.price +
              ' đ | ' +
              item.bill_date
          "
          item-value="id"
          filled
          multiple
          @change="onChange($event)"
          hide-details="auto"
        >
          <template v-slot:item="{ item }">
            <div>
              {{ item.company_out_name
              }}<strong>{{
                ` | Số HĐ: ${item.bill_no} | ${item.price} đ | ${item.bill_date}`
              }}</strong>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <div class="label-form">Chứng từ</div>
        <!-- <v-file-input
          label="Tệp tin"
          outlined
          dense
          @change="handleUpload"
          multiple
        ></v-file-input> -->
        <UploadFile
          :reference_id="form.id"
          :table="TABLE"
          :type="CHUNG_TU"
          :typeAllow="['xlsx', 'xls']"
          :sizeAllow="20"
          v-model="form.file_chung_tu"
          multiple
          :editing="editing"
        />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import UploadFile from "@/components/upload/UploadFile";
import { TABLE, CHUNG_TU } from "@/constants/tokhaihaiquan";
export default {
  props: ["value", "customersList", "companyList", "editing", "billsList"],
  components: { UploadFile },
  data: () => ({
    TABLE,
    CHUNG_TU,
    company_outRules: [(v) => !!v || "Công ty xuất không thể bỏ trống"],
    company_inRules: [(v) => !!v || "Công ty nhập không thể bỏ trống"],
    priceRules: [
      (v) => !!v || "Số tiền không thể bỏ trống",
      (v) => !isNaN(v) || "Vui lòng nhập số",
    ],
    quantityRules: [
      (v) => !!v || "Khối lượng không thể bỏ trống",
      (v) => !isNaN(v) || "Vui lòng nhập số",
    ],
  }),
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    "form.company_in": {
      deep: true,
      handler(val) {
        this.$emit("companyInChange", val, this.companyList);
      },
    },
    "form.company_out": {
      handler(val) {
        this.$emit("companyOutChange", val, this.customersList);
      },
      deep: true,
    },
  },
  methods: {
    handleUpload(e) {
      this.$emit("handleUpload", e);
    },
  },
};
</script>
