var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-row',{staticClass:"pl-7 pr-7"},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"label-form"},[_vm._v("Mã số thuế(Công ty xuất)")]),_c('v-autocomplete',{attrs:{"prepend-inner-icon":"mdi-map-marker","items":_vm.customersList,"outlined":"","dense":"","item-value":"id","item-text":function (item) { return item.name + ' - ' + item.tax; },"rules":_vm.company_outRules,"hide-details":"auto"},model:{value:(_vm.form.company_out),callback:function ($$v) {_vm.$set(_vm.form, "company_out", $$v)},expression:"form.company_out"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"label-form"},[_vm._v("Mã số thuế(Công ty nhập)")]),_c('v-autocomplete',{attrs:{"prepend-inner-icon":"mdi-map-marker","items":_vm.companyList,"outlined":"","dense":"","item-value":"id","item-text":function (item) { return item.code + ' - ' + item.tax; },"rules":_vm.company_inRules,"hide-details":"auto"},model:{value:(_vm.form.company_in),callback:function ($$v) {_vm.$set(_vm.form, "company_in", $$v)},expression:"form.company_in"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"label-form"},[_vm._v("Số tiền")]),_c('v-text-field-money',{attrs:{"placeholder":"Nhập số tiền","rules":_vm.priceRules,"outlined":"","dense":"","hide-details":"auto","properties":{
          prefix: 'VNĐ',
          readonly: false,
          disabled: false,
          outlined: true,
          clearable: false,
          dense: true,
          placeholder: 'Nhập số tiền',
        },"options":{
          locale: 'vi-VN',
          length: 11,
          precision: 0,
          empty: null,
        }},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"label-form"},[_vm._v("Khối lương")]),_c('v-text-field',{attrs:{"rules":_vm.quantityRules,"placeholder":"Nhập khối lượng (kg)","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.form.quantity),callback:function ($$v) {_vm.$set(_vm.form, "quantity", $$v)},expression:"form.quantity"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"label-form"},[_vm._v("Hóa đơn")]),_c('v-autocomplete',{attrs:{"placeholder":"Chọn số hóa đơn","prepend-inner-icon":"mdi-image-area","items":_vm.billsList,"outlined":"","dense":"","item-text":function (item) { return item.company_out_name +
            ' | Số HĐ: ' +
            item.bill_no +
            ' | ' +
            item.price +
            ' đ | ' +
            item.bill_date; },"item-value":"id","filled":"","multiple":"","hide-details":"auto"},on:{"change":function($event){return _vm.onChange($event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.company_out_name)),_c('strong',[_vm._v(_vm._s((" | Số HĐ: " + (item.bill_no) + " | " + (item.price) + " đ | " + (item.bill_date))))])])]}}]),model:{value:(_vm.form.bill),callback:function ($$v) {_vm.$set(_vm.form, "bill", $$v)},expression:"form.bill"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"label-form"},[_vm._v("Chứng từ")]),_c('UploadFile',{attrs:{"reference_id":_vm.form.id,"table":_vm.TABLE,"type":_vm.CHUNG_TU,"typeAllow":['xlsx', 'xls'],"sizeAllow":20,"multiple":"","editing":_vm.editing},model:{value:(_vm.form.file_chung_tu),callback:function ($$v) {_vm.$set(_vm.form, "file_chung_tu", $$v)},expression:"form.file_chung_tu"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }