import request from "../utils/request";

export function index(params ={}) {
  return request({
    url: "chi-nhanh-ngan-hang",
    method: "get",
    params,
  });
}
export function show(id, params ={}) {
  return request({
    url: `chi-nhanh-ngan-hang/${id}`,
    method: "get",
    params,
  });
}
export function create(data) {
  return request({
    url: "chi-nhanh-ngan-hang",
    method: "post",
    data,
  });
}
export function update(id, data) {
  return request({
    url: `chi-nhanh-ngan-hang/${id}`,
    method: "patch",
    data,
  });
}
export function destroy(id) {
  return request({
    url: `chi-nhanh-ngan-hang/${id}`,
    method: "delete",
  });
}
