<template>
  <v-card flat>
    <v-row class="pl-7 pr-7">
      <v-col cols="6">
        <div class="label-form">Công ty</div>
        <v-card-text
          >{{ form.declare_out_company_name }} -
          {{ form.company_out_tax }}</v-card-text
        >
      </v-col>
      <v-col cols="6">
        <div class="label-form">Số tờ khai xuất</div>
        <v-row>
          <v-col>
            <v-text-field
              v-model="form.declare_out_no"
              placeholder="Nhập số tờ khai xuất"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col>
            <v-menu
              v-model="showPickDeclareOutDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.declare_out_date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  placeholder="Nhập ngày tờ khai xuất"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.declare_out_date"
                @input="showPickDeclareOutDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <div class="label-form">Mã hải quan</div>
        <v-autocomplete
          prepend-inner-icon="mdi-map-marker"
          v-model="form.declare_out_custom_id"
          :items="customsAreaList"
          outlined
          dense
          item-value="id"
          :item-text="(item) => item.code + ' - ' + item.name"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="6">
        <div class="label-form">Thông quan</div>
        <v-btn
          :loading="loadingCheck"
          depressed
          width="100%"
          @click="fetchThongQuan"
          :disabled="
            form.declare_out_flow && form.declare_out_flow_status ? true : false
          "
        >
          Kiểm tra thông quan tờ khai xuất
        </v-btn>
      </v-col>
      <v-col cols="3">
        <div class="label-form">Số hoá đơn</div>
        <v-text-field
          v-model="form.so_hd"
          placeholder="Số hoá đơn"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="9">
        <div class="label-form">Luồng tờ khai nhập</div>
        <v-row>
          <v-col>
            <v-autocomplete
              prepend-inner-icon="mdi-map-marker"
              v-model="form.declare_out_flow"
              :items="declareFlowList"
              outlined
              dense
              item-value="id"
              item-text="name"
            >
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              prepend-inner-icon="mdi-map-marker"
              v-model="form.declare_out_flow_status"
              :items="declareStatusList"
              outlined
              dense
              item-value="id"
              item-text="value"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <div class="label-form">Chứng từ</div>
        <!-- <v-file-input
          label="Tệp tin"
          outlined
          dense
          ref="files_declare_out"
          name="files_declare_out"
          @change="handleUploadDeclareOut"
        ></v-file-input> -->
        <UploadFile
          :typeAllow="['xlsx', 'xls', 'pdf']"
          :reference_id="form.id"
          :table="TABLE"
          :type="TOKHAIXUAT"
          :sizeAllow="20"
          v-model="form.file_to_khai_xuat"
          hasImport
          multiple
          @import-item="(item) => handleUploadDeclareOut(item.file)"
          @import-item-pdf="(item) => handleUploadPDF(item.file)"
          :editing="editing"
          noComfirmImport
          :isLoadingHorizontal="isLoadingHorizontal"
        />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import XLSX from "xlsx";
import UploadFile from "@/components/upload/UploadFile";
import { TABLE, TOKHAIXUAT } from "@/constants/tokhaihaiquan";
import { removeVietnameseTones } from "@/utils/Vietnamconvert";
import API from "@/api/tokhaihaiquan";
import JsonToFromData from "@/utils/JsonToFormData.js";
export default {
  components: { UploadFile },
  props: [
    "value",
    "editing",
    "customsAreaList",
    "declareFlowList",
    "declareStatusList",
    "customersList",
    "companyList",
  ],
  data: () => ({
    TABLE,
    TOKHAIXUAT,
    showPickDeclareOutFlowDate: false,
    showPickDeclareOutDate: false,
    files: [],
    loadingCheck: false,
    isLoadingHorizontal: false,
  }),
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async handleUploadPDF(file) {
      this.isLoadingHorizontal = true;
      let formData = new FormData();
      formData.append("file", file);
      const response = await API.importPdfPython(formData);
      let data = response.data.data;
      if (data?.type == "XK") {
        this.handleDataPdf(data);
      } else {
        this.errorMessages("Không đúng mẫu file hải quan tờ khai nhập !");
      }
      this.isLoadingHorizontal = false;
    },
    handleDataPdf(data) {
      if (!data.company_out_tax) {
        return this.errorMessages("Lỗi nhập chứng từ không có mã công ty xuất");
      }
      // handle
      let companyOut = this.customersList.find(
        (item) =>
          item.tax == data.company_out_tax ||
          (data.company_out_name &&
            removeVietnameseTones(item.name).toLowerCase() ==
              removeVietnameseTones(data.company_out_name).toLowerCase())
      );
      let company_in = this.companyList.find((item) => {
        return (
          item.tax == data.company_in_tax ||
          removeVietnameseTones(item.name)
            .toLowerCase()
            .includes(this.handleNameCompanyIn(data.company_in_name))
        );
      });

      const customsArea = this.customsAreaList.find(
        (item) => item.sku == data.custom_code
      );

      // fill
      this.form.so_hd = data.so_hd;
      this.form.company_out = companyOut?.id;
      this.form.company_out_tax = companyOut?.tax;
      this.form.declare_out_company_name = companyOut?.name;
      this.form.company_in = company_in?.id;
      this.form.company_in_tax = company_in?.tax;
      this.form.declare_in_company_name = company_in?.name;
      this.form.declare_out_flow_status = data.flow_status.includes(
        "(thông quan)"
      );
      this.form.price = parseFloat(data.price.replaceAll(".", ""));
      this.form.quantity = data.quantity
        .replaceAll(".", "")
        .replaceAll(",", ".");

      this.form.declare_out_no = data.declare_out_no.replaceAll("*", "");
      this.form.declare_out_flow = data.declare_out_flow;
      this.form.declare_out_flow_date = this.formatDate(
        data.declare_out_flow_date
      );
      this.form.declare_out_date = this.formatDate(data.declare_out_date);
      this.form.declare_out_custom_id = customsArea?.id;
    },
    async fetchThongQuan() {
      this.loadingCheck = true;
      let find_declare_out_custom = this.customsAreaList.find(
        (x) => x.id == this.form.declare_out_custom_id
      );
      const res = await API.checkThongQuan({
        company_tax: this.form.company_out_tax,
        declare_no: this.form.declare_out_no,
        declare_custom: find_declare_out_custom
          ? find_declare_out_custom.code
          : null,
        declare_date: this.form.declare_out_date,
      });
      if (res && res.isDone) {
        let { status, flow, declare_no } = res.data.data;
        if (status) {
          this.form.declare_out_flow_status = true;
        }
        if (flow) {
          this.form.declare_out_flow = flow;
        }
        if (declare_no) {
          this.form.declare_out_no = declare_no;
        }
      }
      this.loadingCheck = false;
    },
    infoMessange(message, timeout = 2500) {
      this.$toast.info(message, {
        position: "top-center",
        timeout,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    errorMessages(message, timeout = 2000) {
      this.$toast.error(message, {
        position: "top-center",
        timeout,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    warningThongQuan() {
      this.$toast.warning("Vui lòng cập nhập đủ thông tin!", {
        position: "top-center",
        timeout: 1500,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    async handleUploadDeclareOut(file) {
      const fileReader = new FileReader(); // construction function that can read the file content
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary", // binary
          });

          const wsname = workbook.SheetNames[0]; //take the first sheet

          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); //Get the data in this table

          //so hợp đồng
          let so_hd = ws.find((item) => item.__rowNum__ === 48);
          if (so_hd) {
            this.form.so_hd = so_hd.__EMPTY_16
              ? so_hd.__EMPTY_16
              : so_hd.__EMPTY_15;
          }

          let companyOut = {
            tax: ws.find((item) => item.__rowNum__ === 12).__EMPTY_4
              ? ws.find((item) => item.__rowNum__ === 12).__EMPTY_4
              : ws.find((item) => item.__rowNum__ === 12).__EMPTY_3,
            name: ws.find((item) => item.__rowNum__ === 13).__EMPTY_4
              ? ws.find((item) => item.__rowNum__ === 13).__EMPTY_4
              : ws.find((item) => item.__rowNum__ === 13).__EMPTY_3,
          };
          let ws_find_company_out = this.customersList.find(
            (item) =>
              item.tax === companyOut.tax ||
              (companyOut.name &&
                removeVietnameseTones(item.name).toLowerCase() ==
                  removeVietnameseTones(companyOut.name).toLowerCase())
          );
          const companyOutId = ws_find_company_out?.id;
          const companyOutTax = ws_find_company_out?.tax;
          const companyOutName = ws_find_company_out?.name;

          let companyIn = {
            tax: ws.find((item) => item.__rowNum__ === 28).__EMPTY_4
              ? ws.find((item) => item.__rowNum__ === 28).__EMPTY_4
              : ws.find((item) => item.__rowNum__ === 28).__EMPTY_3,
            name: ws.find((item) => item.__rowNum__ === 29).__EMPTY_4
              ? ws.find((item) => item.__rowNum__ === 29).__EMPTY_4
              : ws.find((item) => item.__rowNum__ === 29).__EMPTY_3,
          };
          let ws_find_company_in = this.companyList.find((item) => {
            return (
              item.tax == companyIn.tax ||
              removeVietnameseTones(item.name)
                .toLowerCase()
                .includes(this.handleNameCompanyIn(companyIn.name))
            );
          });
          this.form.company_in = ws_find_company_in?.id;
          this.form.company_in_tax = ws_find_company_in?.tax;
          this.form.declare_in_company_name = ws_find_company_in?.name;
          // let flow = "0";
          let flowStatus = false;
          let _title = ws.find((item) => item.__rowNum__ === 1).__EMPTY_3
            ? ws.find((item) => item.__rowNum__ === 1).__EMPTY_3
            : ws.find((item) => item.__rowNum__ === 1).__EMPTY_2;
          if (_title.includes("(thông quan)")) {
            flowStatus = true;
          }

          this.form.company_out = companyOutId;
          this.form.company_out_tax = companyOutTax;

          let _price = ws.find((item) => item.__rowNum__ === 53).__EMPTY_19
            ? ws.find((item) => item.__rowNum__ === 53).__EMPTY_19
            : ws.find((item) => item.__rowNum__ === 53).__EMPTY_18;

          this.form.price = parseFloat(_price.replaceAll(".", ""));

          let _quantity = ws.find((item) => item.__rowNum__ === 40).__EMPTY_6
            ? ws.find((item) => item.__rowNum__ === 40).__EMPTY_6
            : ws.find((item) => item.__rowNum__ === 40).__EMPTY_5;
          this.form.quantity = _quantity
            .replaceAll(".", "")
            .replaceAll(",", ".");
          this.form.declare_out_company_id = companyOutId;
          this.form.declare_out_company_name = companyOutName;

          let _declare_out_no = ws.find((item) => item.__rowNum__ === 2)
            .__EMPTY_21
            ? ws.find((item) => item.__rowNum__ === 2).__EMPTY_21
            : ws.find((item) => item.__rowNum__ === 2).__EMPTY_20;
          this.form.declare_out_no = _declare_out_no.replaceAll("*", "");

          this.form.declare_out_flow = ws.find((item) => item.__rowNum__ === 5)
            .__EMPTY_4
            ? ws.find((item) => item.__rowNum__ === 5).__EMPTY_4
            : ws.find((item) => item.__rowNum__ === 5).__EMPTY_3;
          this.form.declare_out_flow_status = flowStatus;
          this.form.declare_out_flow_date = this.formatDate(
            ws.find((item) => item.__rowNum__ === 50).__EMPTY_17
              ? ws.find((item) => item.__rowNum__ === 50).__EMPTY_17
              : ws.find((item) => item.__rowNum__ === 50).__EMPTY_16
          );
          const customCode = ws.find((item) => item.__rowNum__ === 6).__EMPTY_8
            ? ws.find((item) => item.__rowNum__ === 6).__EMPTY_8
            : ws.find((item) => item.__rowNum__ === 6).__EMPTY_7;

          const customsArea = this.customsAreaList.find(
            (item) => item.sku === customCode
          );
          const customId = customsArea?.id;
          const customName = customsArea?.name;
          let ws_find;
          if (flowStatus) {
            ws_find = ws.find((item) => item.__rowNum__ === 7).__EMPTY_4;
          } else {
            ws_find = ws.find((item) => item.__rowNum__ === 7).__EMPTY_4;
          }

          this.form.declare_out_date = this.formatDate(ws_find);
          this.form.declare_out_custom_id = customId;

          this.infoMessange("Thành công");
        } catch (e) {
          this.errorMessages("Lỗi nhập chứng từ");
          console.log(e);
        }
      };

      fileReader.readAsBinaryString(file); // read file, trigger onload
      // this.$refs["files_declare_out"].value = null;
    },
    handleNameCompanyIn(name) {
      if (!name) {
        return "";
      }
      name == removeVietnameseTones(name);
      name = name.toLowerCase();
      if (name.includes("thuong mai dich vu")) {
        return "thuong mai dich vu";
      } else if (name.includes("tnhh") || name.includes("greenstar")) {
        return "tnhh";
      } else if (name.includes("thuan thanh")) {
        return "thuan thanh";
      }
      return name;
    },
    formatDate(date) {
      const splitDate = date.split(" ")[0];
      const day = splitDate.split("/")[0];
      const month = splitDate.split("/")[1];
      const year = splitDate.split("/")[2];
      if (year && month && day) {
        return year + "-" + month + "-" + day;
      }
    },
  },
};
</script>
